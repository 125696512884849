.code-input {
  @apply py-8 h-auto !w-full !flex gap-4 justify-center items-center;
}

.character {
  @apply flex items-center justify-center max-w-[61px] w-[61px] h-[83px] text-base font-normal text-center text-text-main placeholder:text-text-disabled disabled:!text-hubs-secondary disabled:!bg-hubs-background-accent rounded disabled:cursor-not-allowed shadow-md border;
}

.character--inactive {
  @apply bg-white;
}

.character--selected {
  @apply ring-0 outline-1 relative;
}

.character--selected:not(.character--filled) {
  color: transparent;
}

.character--selected:not(.character--filled)::after {
  content: '';
  width: 1px;
  height: 25px;
  background-color: #333;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: input-blink 1s infinite step-end;
}

@keyframes input-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
