.compact-editor :global(.public-DraftEditor-content) {
  @apply min-h-11;
}

.editor :global(.public-DraftEditor-content) {
  @apply min-h-52;
}

.text-align-center :global(.public-DraftStyleDefault-ltr) {
  @apply text-center;
}

.text-align-left :global(.public-DraftStyleDefault-ltr) {
  @apply text-left;
}

.text-align-right :global(.public-DraftStyleDefault-ltr) {
  @apply text-right;
}
