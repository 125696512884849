.default {
  @apply rounded-lg border bg-hubs-background hover:shadow-lg transition duration-200 ease-linear hover:cursor-pointer text-hubs-primary overflow-hidden;
}

.transparent {
  @apply bg-transparent;
}

.no-border {
  @apply border-none;
}

.no-hover {
  @apply hover:cursor-default hover:shadow-none;
}
