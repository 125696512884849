body {
  -webkit-font-smoothing: antialiased;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #000;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #000, 0 0 5px #000;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
